import React, { useEffect, useState } from "react";

const Notificaciones = () => {
    const [notificaciones, setNotificaciones] = useState([]);

    useEffect(() => {
        const url_tablas = `${process.env.REACT_APP_SERVER_PORT}/logs-app/notificaciones`;

        const requestTablas = {
            method: "GET",
            redirect: "follow",
            headers: {
                authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`
            }
        };

        fetch(url_tablas, requestTablas)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(result => {
                if (result && result.results) {
                    setNotificaciones(result.results);
                    console.log("Notificaciones", result.results);
                } else {
                    console.log("No se encontraron notificaciones");
                }
            })
            .catch(error => console.error("Error al obtener las notificaciones:", error));
    }, []);

    const handleDelete = (id) => {
        setNotificaciones(notificaciones.filter((item) => item.id !== id));
    };

    return (
        <div className="cont-notificaciones">
            {notificaciones && notificaciones.length > 0 ? (
                notificaciones.map((notificacion) => {
                    if (notificacion.values && notificacion.values.mostrar && notificacion.values.mostrar.name === "Mostrar") {
                        return (
                            <div key={notificacion.id} id={notificacion.id} className={"notificacion " + (notificacion.values.color ? notificacion.values.color.name : "")}>
                                <p>
                                    {notificacion.values.texto}
                                </p>
                                <div className="Close" onClick={() => handleDelete(notificacion.id)}>
                                    X
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })
            ) : (
                <></>
            )}
        </div>
    );
};

export default Notificaciones;
