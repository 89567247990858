import React, { useContext, useState, useEffect } from 'react';
import InmueblesContext from '../contextos/InmueblesContext';
import { useAuth0 } from "@auth0/auth0-react";
import logoTownCenter from "../images/SP-TownCenter.png";
import logoGardens from "../images/SP-Gardens.png";
import logoSkyView from "../images/SP-SkyViewTowers.png";
import logoAzul from "../images/logo_SP_azul.png";

const CotizacionFinalTabla = () => {
    const { isAuthenticated, user } = useAuth0();
    const { inmuebleData, accesos, nombreTabla, abrirDetalle, inmuebleActivo, setInmuebleActivo, cerrarDetalle, inmuebleSeleccionado, date, setInmuebleSeleccionado, formCotizacion, setFormCotizacion, parametrosProyectos } = useContext(InmueblesContext);

    const [logoProyecto, setLogoProyecto] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [email, setEmail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [cedula, setCedula] = useState(0);
    const [ciudad, setCiudad] = useState("");
    const [proyecto, setProyecto] = useState("");
    const [inmuebleCotizado, setInmuebleCotizado] = useState("");


    useEffect(() => {
      if (isAuthenticated && user && accesos.length > 0) {
        const usuarioAcceso = accesos.find(acceso => acceso.values.correo === user.email);
        const userTelefono = usuarioAcceso.length > 0 && usuarioAcceso[0].values.telefono ? usuarioAcceso[0].values.telefono : "099 933 1700";

        if (usuarioAcceso) {
          setTelefono(userTelefono);
        }
      }
    }, [isAuthenticated, user, accesos]);

    {/* 
      useEffect(() => {
        if (accesos && user) {
          const accesoAdmin = accesos.filter(usuario => usuario.values.correo === user.email);
          const userTelefono = accesoAdmin.length > 0 && accesoAdmin[0].values.telefono ? accesoAdmin[0].values.telefono : "099 933 1700";
          setTelefono(userTelefono);
        }
      }, [accesos, user]);
    */}

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormReady") {
                let nombreInput = document.querySelector(".hs-input[name='firstname']");
                setNombre(nombreInput ? nombreInput.value : "");
                if (nombreInput) {
                    nombreInput.addEventListener("change", () => {
                        setNombre(nombreInput.value);
                    });
                }

                let apellidoInput = document.querySelector(".hs-input[name='lastname']");
                setApellido(apellidoInput ? apellidoInput.value : "");
                if (apellidoInput) {
                    apellidoInput.addEventListener("change", () => {
                        setApellido(apellidoInput.value);
                    });
                }

                let emailInput = document.querySelector(".hs-input[name='email']");
                setEmail(emailInput ? emailInput.value : "");
                if (emailInput) {
                    emailInput.addEventListener("change", () => {
                        setEmail(emailInput.value);
                    });
                }

                let ciudadInput = document.querySelector(".hs-input[name='ciudad_']");
                setCiudad(ciudadInput ? ciudadInput.value : "");
                if (ciudadInput) {
                    ciudadInput.addEventListener("change", () => {
                        setCiudad(ciudadInput.value);
                    });
                }

                let telefonoInput = document.querySelector(".hs-input[name='phone']");
                setTelefono(telefonoInput ? telefonoInput.value : "");
                if (telefonoInput) {
                    telefonoInput.addEventListener("change", () => {
                        setTelefono(telefonoInput.value);
                    });
                }

                if (inmuebleData) {
                    Object.entries(inmuebleData).forEach(([key, data]) => {
                        try {
                            const input = document.querySelector(`.hs-input[name='${key}']`);
                            if (input) {
                                input.value = data;
                            }
                        } catch (e) {
                            console.error(`fallo al agregar el valor de ${key}`);
                        }
                    });
                }

                console.log("Form Listo", inmuebleData);
            }
        });
    }, [inmuebleData]);

    // Verificación para evitar acceder a propiedades no definidas
    if (!parametrosProyectos || !parametrosProyectos[nombreTabla]) {
        return <div>Loading...</div>;
    }

    return (
        <div id="cotizacionImprimir">
            <header className="cotizacion-header">
                <img src={logoAzul} className="App-logo" alt="logo" width="150"/>
                <img src={logoProyecto} className="App-logo" alt="logo" width="150"/>
                <div className='datosAsesor'>
                    <p>{user.name}</p>
                    <p>{user.email}</p>
                    <p>{telefono ? telefono : "099 933 1700"}</p>
                    <p>{date}</p>
                </div>
            </header>

             <div key={inmuebleSeleccionado} className="detalleCotizacion">
                          <>
                            <div>
                              <h3>CLIENTE</h3>
                              <table className="dato-cliente">
                                <thead>
                                  <tr>
                                    <th>NOMBRE</th>
                                    <th>APELLIDO</th>
                                    <th>CORREO</th>
                                    <th>TELÉFONO</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{nombre}</td>
                                    <td>{apellido}</td>
                                    <td>{email}</td>
                                    <td>{telefono}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <h3>COTIZACIÓN {inmuebleData.inmueblecotizado} </h3>
                              <table className="dato-inmueble">
                                {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
                                  <>
                                    <tr>
                                      <td>ÁREA:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.area.toLocaleString("en")}</td>
                                    </tr>
                                    <tr>
                                      <td>VALOR POR (m²):</td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {inmuebleData.areaprecio.toLocaleString("en", {
                                          style: "currency",
                                          currency: "USD",
                                        })}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>TOTAL:</td>
                                      <td></td>
                                      <td></td>
                                      <td className="resalt">
                                        {inmuebleData.preciototal.toLocaleString("en", {
                                          style: "currency",
                                          currency: "USD",
                                        })}
                                      </td>
                                    </tr>
                                    
                                  </>
                                ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
                                  nombreTabla === "TOWN CENTER OFICINAS" ? (
                                  <>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>M²</th>
                                        <th>US$</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>ÁREA INTERNA:</td>
                                        <td>{inmuebleData.area}</td>
                                        <td>
                                          {inmuebleData.areaprecio.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(
                                            inmuebleData.area * inmuebleData.areaprecio
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DEL BALCÓN:</td>
                                        <td>{inmuebleData.areabalcon}</td>
                                        <td>
                                          {inmuebleData.preciobalcon.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areabalcon * inmuebleData.preciobalcon).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DE AZOTEA:</td>
                                        <td>{inmuebleData.areaazotea}</td>
                                        <td>
                                          {inmuebleData.precioazotea.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areaazotea * inmuebleData.precioazotea).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>BODEGA:</td>
                                        <td id="cant_bodegas">{inmuebleData.bodega}</td>
                                        <td></td>
                                        <td>
                                          {(inmuebleData.bodega * inmuebleData.preciobodega).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>PARQUEO:</td>
                                        <td id="cant_parqueos">{inmuebleData.parqueo} uni.</td>
                                        <td></td>
                                        <td>
                                          {(inmuebleData.parqueo).toLocaleString(
                                            "en",
                                            { style: "currency", currency: "USD" }
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>TOTAL:</td>
                                        <td></td>
                                        <td></td>
                                        <td className="resalt">
                                          {(
                                            inmuebleData.preciototal
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      
                                    </tbody>
                                  </>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>M²</th>
                                        <th>US$</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>ÁREA INTERNA:</td>
                                        <td>{inmuebleData.area}</td>
                                        <td>
                                          {parseInt(
                                            inmuebleData.areaprecio
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(
                                            inmuebleData.area * inmuebleData.areaprecio
                                          ).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DEL BALCÓN:</td>
                                        <td>{inmuebleData.areabalcon}</td>
                                        <td>
                                          {parseInt(inmuebleData.preciobalcon).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areabalcon * inmuebleData.preciobalcon).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DE PATIO:</td>
                                        <td>{inmuebleData.areapatio}</td>
                                        <td>
                                          {parseInt(inmuebleData.preciopatio).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areapatio * inmuebleData.preciopatio).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>ÁREA DE AZOTEA:</td>
                                        <td>{inmuebleData.areaazotea}</td>
                                        <td>
                                          {parseInt(inmuebleData.precioazotea).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                        <td>
                                          {(inmuebleData.areaazotea * inmuebleData.precioazotea).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>BODEGA:</td>
                                        <td id="cant_bodegas">{inmuebleData.bodega}</td>
                                        <td></td>
                                        <td>
                                          {(inmuebleData.bodega * inmuebleData.preciobodega).toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>PARQUEO:</td>
                                        <td id="cant_parqueos">{inmuebleData.cantparqueo} uni.</td>
                                        <td></td>
                                        <td>
                                          {inmuebleData.parqueo }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>TOTAL:</td>
                                        <td></td>
                                        <td></td>
                                        <td className="resalt">
                                          {inmuebleData.preciototal.toLocaleString("en", {
                                            style: "currency",
                                            currency: "USD",
                                          })}
                                        </td>
                                      </tr>
                                      
                                    </tbody>
                                  </>
                                )}
                              </table>
                          <h2>FORMA DE PAGO</h2>
                          <table>
                              { nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ?
                              <tbody>
                                  <tr>
                                      <td>RESERVA:</td>
                                      <td>{inmuebleData.porcentaje_reserva}%</td>
                                      <td>US$</td>
                                      <td>{(((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_reserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>A LA FIRMA DEL CONTRATO:</td>
                                      <td>{inmuebleData.porcentaje_firma}%</td>
                                      <td>US$</td>
                                      <td>{(((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_firma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>ABONO A GASTOS LEGALES:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CRÉDITO DIRECTO:</td>
                                      <td>{inmuebleData.porcentaje_credito}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CUOTAS MENSUALES:</td>
                                      <td>{parametrosProyectos[nombreTabla].cuotas}</td>
                                      <td>US$</td>
                                      <td>{(((((inmuebleData.area * 
                                      inmuebleData.areaprecio) * 
                                      inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono) 
                                      / parametrosProyectos[nombreTabla].cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CONTRA ENTREGA:</td>
                                      <td>{inmuebleData.porcentaje_contra_entrega}%</td>
                                      <td>US$</td>
                                      <td>{(((inmuebleData.area * inmuebleData.areaprecio) * inmuebleData.porcentaje_contra_entrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                              </tbody>
                              : nombreTabla === "TOWN CENTER DEPARTAMENTOS" || nombreTabla === "TOWN CENTER OFICINAS" ?
                              <tbody>
                                  <tr>
                                      <td>RESERVA:</td>
                                      <td>{inmuebleData.porcentaje_reserva}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_reserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>A LA FIRMA DEL CONTRATO:</td>
                                      <td>{inmuebleData.porcentaje_firma}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_firma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>ABONO A GASTOS LEGALES:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CRÉDITO DIRECTO:</td>
                                      <td>{inmuebleData.porcentaje_credito}%</td>
                                      <td>US$</td>
                                      <td>{(((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CUOTAS MENSUALES:</td>
                                      <td>{parametrosProyectos[nombreTabla].cuotas}</td>
                                      <td>US$</td>
                                      <td>{((((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono) / parametrosProyectos[nombreTabla].cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CONTRA ENTREGA:</td>
                                      <td>{inmuebleData.porcentaje_contra_entrega}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_contra_entrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                              </tbody>
                              :
                              <tbody>
                                  <tr>
                                      <td>RESERVA:</td>
                                      <td>{inmuebleData.porcentaje_reserva}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.bodega * inmuebleData.preciobodega)) * inmuebleData.porcentaje_reserva) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>A LA FIRMA DEL CONTRATO:</td>
                                      <td>{inmuebleData.porcentaje_firma}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.bodega * inmuebleData.preciobodega)) * inmuebleData.porcentaje_firma) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>ABONO A GASTOS LEGALES:</td>
                                      <td></td>
                                      <td></td>
                                      <td>{inmuebleData.abono.toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CRÉDITO DIRECTO:</td>
                                      <td>{inmuebleData.porcentaje_credito}%</td>
                                      <td>US$</td>
                                      <td>{(((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CUOTAS MENSUALES:</td>
                                      <td>{parametrosProyectos[nombreTabla].cuotas}</td>
                                      <td>US$</td>
                                      <td>{((((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_credito) / 100) + inmuebleData.abono) / parametrosProyectos[nombreTabla].cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                                  <tr>
                                      <td>CONTRA ENTREGA:</td>
                                      <td>{inmuebleData.porcentaje_contra_entrega}%</td>
                                      <td>US$</td>
                                      <td>{((((inmuebleData.area * inmuebleData.areaprecio) + (inmuebleData.areabalcon * inmuebleData.preciobalcon) + (inmuebleData.areapatio * inmuebleData.preciopatio) + (inmuebleData.areaazotea * inmuebleData.precioazotea) + (inmuebleData.parqueo ) + (inmuebleData.preciobodega * inmuebleData.bodega)) * inmuebleData.porcentaje_contra_entrega) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</td>
                                  </tr>
                              </tbody>
                              }
                          </table>
                            </div>
                          </>
              
                          <div className="disclaimer">
                            <p>
                            La información textual y gráfica aquí presentada es orientativa, pudiendo estar sujeta a modificaciones de carácter técnico derivadas del proyecto y su ejecución. Los precios pueden variar de acuerdo a la
              evolución de las condiciones del mercado durante la comercialización de los inmuebles. La empresa se reserva el derecho de efectuar dichas modificaciones. San Patricio Smart Living. Todos los derechos reservados.
                            </p>
                          </div>
                        </div>
        </div>
    )
}

export default CotizacionFinalTabla;
