import React, { useState, useEffect, useContext } from "react";
import DatosCotizacion from "./DatosCotizacion";
import { useAuth0 } from "@auth0/auth0-react";
import DetalleInmueble from "./DetalleInmueble";
import CotizacionFinal from "./CotizacionFinal";
import inmueble from "../helpers/inmueble";
import InmueblesContext from "../contextos/InmueblesContext";

const Cotizador = ({ inmuebles, cantInmuebles, nombreTabla, accesos, parametrosProyectos }) => {
  const [camposBloqueados, setCamposBloqueados] = useState(true);
  const { isAuthenticated, user } = useAuth0();
  const [personalizado, setPersonalizado] = useState(false);
  const { handlerInmueble } = useContext(InmueblesContext);

  const cuotasProyecto = parametrosProyectos[nombreTabla]?.cuotas || parametroCuotas;

  useEffect(() => {
    const accesoAdmin = accesos.filter(
      (usuario) =>
        usuario.values.correo === user.email &&
        usuario.values.acceso.name === "Admin"
    );
    accesoAdmin.length >= 1
      ? setCamposBloqueados(false)
      : setCamposBloqueados(true);
  }, [user]);

  const [inmuebleSeleccionado, setInmuebleSeleccionado] = useState("seleccionar");
  const [area, setArea] = useState(0);
  const [metrosCuadrados, setMetrosCuadrados] = useState(0);
  const [total, setTotal] = useState(0);
  const [abono, setAbono] = useState(3000);
  const [reserva, setReserva] = useState(0);
  const [firma, setFirma] = useState(0);
  const [cuotas, setCuotas] = useState(cuotasProyecto);
  const [abonoGastosLegales, setAbonoGastosLegales] = useState(3000);
  const [contraEntrega, setContraEntrega] = useState(0);
  const [credito, setCredito] = useState(0);

  const [areaAzotea, setAreaAzotea] = useState(0);
  const [areaPatio, setAreaPatio] = useState(0);
  const [areaInterna, setAreaInterna] = useState(0);
  const [areaBalcon, setAreaBalcon] = useState(0);
  const [parqueo, setParqueo] = useState(0);
  const [bodega, setBodega] = useState(0);
  const [metrosCuadradosInternos, setMetrosCuadradosInternos] = useState(0);

  const [precioBalcon, setPrecioBalcon] = useState(0);
  const [precioPatio, setPrecioPatio] = useState(0);
  const [precioParqueo, setPrecioParqueo] = useState(0);
  const [precioBodega, setPrecioBodega] = useState(800);
  const [precioAzotea, setPrecioAzotea] = useState(0);

  const [cantParqueos, setCantParqueos] = useState(0);
  const [cantBodegas, setCantBodegas] = useState(0.0);

  const [disponibilidad, setDisponibilidad] = useState("");

  const [parametroReserva, setParametroReserva] = useState(1);
  const [parametroFirma, setParametroFirma] = useState(9);
  const [parametroCredito, setParametroCredito] = useState(40);
  const [parametroCuotas, setParametroCuotas] = useState(cuotasProyecto);
  const [parametroEntrega, setParametroEntrega] = useState(50);

  const [inputArea, setInputArea] = useState(0);
  const [inputPrecio, setInputPrecio] = useState(0);
  const [inputReserva, setInputReserva] = useState(0);
  const [inputFirma, setInputFirma] = useState(0);
  const [inputGastos, setInputGastos] = useState(0);
  const [inputCuotas, setInputCuotas] = useState(0);
  const [inputCredito, setInputCredito] = useState(0);
  const [inputBodega, setInputBodega] = useState(0);
  const [inputBalcon, setInputBalcon] = useState(0);
  const [inputPatio, setInputPatio] = useState(0);
  const [inputAzotea, setInputAzotea] = useState(0);
  const [inputParqueo, setInputParqueo] = useState(0);

  const [inputPrecioArea, setInputPrecioArea] = useState(0);
  const [inputPrecioBalcon, setInputPrecioBalcon] = useState(0);
  const [inputPrecioPatio, setInputPrecioPatio] = useState(0);
  const [inputPrecioAzotea, setInputPrecioAzotea] = useState(0);
  const [inputPrecioParqueo, setInputPrecioParqueo] = useState(0);
  const [inputPrecioBodega, setInputPrecioBodega] = useState(0);

  const [claseInput, setClaseInput] = useState(false);

  const [customizada, setCustomizada] = useState(false);

  useEffect(() => {
    setInmuebleSeleccionado("seleccionar");
    setCustomizada(false);
    setPrecioPatio(0);
    setPrecioAzotea(0);
    setInputPrecioBodega(0);
    setInputPrecioParqueo(0);
    setInputPrecioAzotea(0);
    setInputPrecioPatio(0);
    setPrecioBalcon(0);
    setMetrosCuadradosInternos(0);
    setInputPrecioArea(0);
    setInputPrecio(0);
    setInputPrecioBodega(0);
    setInputPrecioParqueo(0);
    setInputPrecioAzotea(0);
    setInputPrecioPatio(0);
    setInputPrecioBalcon(0);
    setInputPrecioArea(0);
    setAreaBalcon(0);
    setAreaInterna(0);
    setInputArea(0);
    setInputPrecio(0);
    setInputReserva(0);
    setInputFirma(0);
    setInputGastos(0);
    setInputCuotas(0);
    setInputCredito(0);
    setInputBalcon(0);
    setInputPatio(0);
    setInputAzotea(0);
    setInputParqueo(0);
    setInputBodega(0);
    setInputPrecioArea(0);
    setInputPrecioBalcon(0);
    setInputPrecioPatio(0);
    setInputPrecioAzotea(0);
    setInputPrecioParqueo(0);
    setInputPrecioBodega(0);
  }, [nombreTabla]);

  useEffect(() => {
    if (inmuebleSeleccionado !== "seleccionar") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
        setCuotas(cuotasProyecto);
        setAbonoGastosLegales(3000);
        setAbono(3000);
        setParametroCredito(40);
        setParametroEntrega(50);
      } else {
        setCuotas(cuotasProyecto);
        setAbonoGastosLegales(5000);
        setAbono(5000);
        setParametroCredito(30);
        setParametroEntrega(60);
      }

      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
        if (customizada === false) {
          const filtroTerrenoSeleccionado = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          if (filtroTerrenoSeleccionado.length > 0) {
            const area = filtroTerrenoSeleccionado[0].values.area ?
              parseFloat(filtroTerrenoSeleccionado[0].values.area.replace(/,/g, ".")).toFixed(2) : 0;
            const precioM2 = filtroTerrenoSeleccionado[0].values.preciom2 ?
              parseFloat(filtroTerrenoSeleccionado[0].values.preciom2.replace(/,/g, ".")).toFixed(2) : 0;

            setAreaInterna(area);
            setMetrosCuadradosInternos(precioM2);
            setInmuebleSeleccionado(filtroTerrenoSeleccionado[0].id);
            setTotal(area * precioM2);
            setDisponibilidad(filtroTerrenoSeleccionado[0].values.disponibilidad.name);
          }
        }
        setReserva(((area * metrosCuadrados) / 100) * parametroReserva);
        setFirma(((area * metrosCuadrados) / 100) * parametroFirma);
        setContraEntrega(((area * metrosCuadrados) / 100) * parametroCredito + abono);
        setCredito((area * metrosCuadrados * parametroEntrega) / 100);
      }

      if (nombreTabla === "SKY VIEW TORRE 1" || nombreTabla === "SKY VIEW TORRE 2") {
        if (customizada === false) {
          const filtroInmuebleSkyView = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          if (filtroInmuebleSkyView.length > 0) {
            const areaInterna = filtroInmuebleSkyView[0].values.area_m2 ?
              parseFloat(filtroInmuebleSkyView[0].values.area_m2.replace(/,/g, ".")).toFixed(2) : 0;
            const precioM2Interno = filtroInmuebleSkyView[0].values.precio_por_m2_interno ?
              parseFloat(filtroInmuebleSkyView[0].values.precio_por_m2_interno.replace(/,/g, ".")).toFixed(2) : 0;

            setAreaInterna(areaInterna);
            setMetrosCuadradosInternos(precioM2Interno);
            setInmuebleSeleccionado(filtroInmuebleSkyView[0].id);
            setTotal(areaInterna * precioM2Interno);
            setDisponibilidad(filtroInmuebleSkyView[0].values.disponibilidad.name);

            const areaAzotea = filtroInmuebleSkyView[0].values.azotea_m2 ?
              parseFloat(filtroInmuebleSkyView[0].values.azotea_m2.replace(/,/g, ".")).toFixed(2) : 0;
            const areaBalcon = filtroInmuebleSkyView[0].values.balcon_m2 ?
              parseFloat(filtroInmuebleSkyView[0].values.balcon_m2.replace(/,/g, ".")).toFixed(2) : 0;
            const parqueo = filtroInmuebleSkyView[0].values.parqueo ?
              parseFloat(filtroInmuebleSkyView[0].values.parqueo.replace(/,/g, ".")).toFixed(2) : 0;
            const areaPatio = filtroInmuebleSkyView[0].values.patio_m2 ?
              parseFloat(filtroInmuebleSkyView[0].values.patio_m2.replace(/,/g, ".")).toFixed(2) : 0;

            const precioBalcon = filtroInmuebleSkyView[0].values.precio_de_balcon ?
              parseFloat(filtroInmuebleSkyView[0].values.precio_de_balcon.replace(/,/g, ".")).toFixed(2) : 0;
            const precioPatio = filtroInmuebleSkyView[0].values.precio_del_patio_azotea ?
              parseFloat(filtroInmuebleSkyView[0].values.precio_del_patio_azotea.replace(/,/g, ".")).toFixed(2) : 0;
            const precioAzotea = filtroInmuebleSkyView[0].values.precio_del_patio_azotea ?
              parseFloat(filtroInmuebleSkyView[0].values.precio_del_patio_azotea.replace(/,/g, ".")).toFixed(2) : 0;

            setAreaAzotea(areaAzotea);
            setAreaBalcon(areaBalcon);
            setParqueo(parqueo);
            setAreaPatio(areaPatio);

            setPrecioBalcon(precioBalcon);
            setPrecioPatio(precioPatio);
            setPrecioParqueo(16000);
            setPrecioBodega(800);
            setPrecioAzotea(precioAzotea);

            if (nombreTabla === "SKY VIEW TORRE 2") {
              setBodega(0);
            }
          }
        }
        setCantParqueos(2);
        setCantBodegas(0.0);

        setAbono(5000);
        setParametroCredito(30);
        setParametroEntrega(60);

        setReserva((total / 100) * parametroReserva);
        setFirma((total / 100) * parametroFirma);
        setContraEntrega((total / 100) * parametroCredito + abono);
        setCredito((total * parametroEntrega) / 100);
      }

      if (
        nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
        nombreTabla === "TOWN CENTER OFICINAS" ||
        nombreTabla === "TOWN CENTER OFICINAS T1"
      ) {
        if (customizada === false) {
          const filtroInmuebleTownCenter = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          if (filtroInmuebleTownCenter.length > 0) {
            const areaInterna = filtroInmuebleTownCenter[0].values.area_m2 ?
              parseFloat(filtroInmuebleTownCenter[0].values.area_m2.replace(/,/g, ".")).toFixed(2) : 0;
            const precioM2Interno = filtroInmuebleTownCenter[0].values.precio_por_m2_interno ?
              parseFloat(filtroInmuebleTownCenter[0].values.precio_por_m2_interno.replace(/,/g, ".")).toFixed(2) : 0;

            setAreaInterna(areaInterna);
            setMetrosCuadradosInternos(precioM2Interno);
            setInmuebleSeleccionado(filtroInmuebleTownCenter[0].id);
            setTotal(areaInterna * precioM2Interno);
            setDisponibilidad(filtroInmuebleTownCenter[0].values.disponibilidad.name);

            const areaAzotea = filtroInmuebleTownCenter[0].values.patio_azotea_m2 ?
              parseFloat(filtroInmuebleTownCenter[0].values.patio_azotea_m2.replace(/,/g, ".")).toFixed(2) : 0;
            const areaBalcon = filtroInmuebleTownCenter[0].values.balcon_m2 ?
              parseFloat(filtroInmuebleTownCenter[0].values.balcon_m2.replace(/,/g, ".")).toFixed(2) : 0;
            const parqueo = filtroInmuebleTownCenter[0].values.parqueo ?
              parseFloat(filtroInmuebleTownCenter[0].values.parqueo.replace(/,/g, ".")).toFixed(2) : 0;

            const precioBalcon = filtroInmuebleTownCenter[0].values.precio_del_balcon ?
              parseFloat(filtroInmuebleTownCenter[0].values.precio_del_balcon.replace(/,/g, ".")).toFixed(2) : 0;
            const precioPatio = filtroInmuebleTownCenter[0].values.precio_del_patio_azotea ?
              parseFloat(filtroInmuebleTownCenter[0].values.precio_del_patio_azotea.replace(/,/g, ".")).toFixed(2) : 0;
            const precioParqueo = filtroInmuebleTownCenter[0].values.precio_de_parqueo ?
              parseFloat(filtroInmuebleTownCenter[0].values.precio_de_parqueo.replace(/,/g, ".")).toFixed(2) : 0;
            const precioAzotea = filtroInmuebleTownCenter[0].values.precio_del_patio_azotea ?
              parseFloat(filtroInmuebleTownCenter[0].values.precio_del_patio_azotea.replace(/,/g, ".")).toFixed(2) : 0;

            setAreaAzotea(areaAzotea);
            setAreaBalcon(areaBalcon);
            setParqueo(parqueo);
            setBodega(6.25);

            setPrecioBalcon(precioBalcon);
            setPrecioPatio(precioPatio);
            setPrecioParqueo(precioParqueo);
            setPrecioBodega(800);
            setPrecioAzotea(precioAzotea);
          }
        }
        setAbono(5000);
        setParametroCredito(30);
        setParametroEntrega(60);

        setReserva((total / 100) * parametroReserva);
        setFirma((total / 100) * parametroFirma);
        setContraEntrega((total / 100) * parametroCredito + abono);
        setCredito((total * parametroEntrega) / 100);

        setCantParqueos(1);
        setCantBodegas(0.0);
      }
    } else {
      setInmuebleSeleccionado("seleccionar");
    }
  }, [inmuebleSeleccionado, cuotasProyecto]);

  const opcionSeleccionada = (e) => {
    setCustomizada(false);
    setInmuebleSeleccionado(e.target.value);
    setInputArea(0);
    setInputPrecio(0);
    setInputReserva(0);
    setInputFirma(0);
    setInputGastos(0);
    setInputCuotas(0);
    setInputCredito(0);
    setInputBalcon(0);
    setInputPatio(0);
    setInputAzotea(0);
    setInputParqueo(0);
    setInputBodega(0);
    setInputPrecioArea(0);
    setInputPrecioBalcon(0);
    setInputPrecioPatio(0);
    setInputPrecioAzotea(0);
    setInputPrecioParqueo(0);
    setInputPrecioBodega(0);
  };

  const handlerPersonalizado = () => {
    let customInputs = document.querySelectorAll(".customInputs input");
    let custom = false;

    customInputs.forEach((input) => {
      if (input.value != 0) {
        custom = true;
      }
    });

    if (custom == false) {
      setPersonalizado(false);
    } else {
      setPersonalizado(true);
    }
  };

  const cambiarReserva = (e) => {
    setParametroReserva(e.target.value);
    setInputReserva(e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    setParametroEntrega(
      100 -
        (parseFloat(parametroCredito) +
          parseFloat(parametroFirma) +
          parseFloat(e.target.value))
    );

    if (e.target.value === 0 || e.target.value === "") {
      setParametroReserva(1);
    }
  };

  const cambiarFirma = (e) => {
    setParametroFirma(e.target.value);
    setInputFirma(e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    setParametroEntrega(
      100 -
        (parseFloat(parametroCredito) +
          parseFloat(e.target.value) +
          parseFloat(parametroFirma))
    );

    if (e.target.value === 0 || e.target.value === "") {
      setParametroFirma(9);
    }
  };

  const cambiarGastos = (e) => {
    setAbono(e.target.value);
    setInputGastos(e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
        if (e.target.value === 0 || e.target.value === "") {
          setAbono(3000);
        }
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          setAbono(5000);
        }
      }
    }
  };

  const cambiarCredito = (e) => {
    setParametroCredito(e.target.value);
    setInputCredito(e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    setParametroEntrega(
      100 -
        (parseFloat(e.target.value) +
          parseFloat(parametroReserva) +
          parseFloat(parametroFirma))
    );

    if (e.target.value === 0 || e.target.value === "") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
        if (e.target.value === 0 || e.target.value === "") {
          setParametroCredito(40);
        }
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          setParametroCredito(30);
        }
      }
    }
  };

  const cambiarCuotas = (e) => {
    setParametroCuotas(e.target.value);
    setInputCuotas(e.target.value);
    setCuotas(e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
        if (e.target.value === 0 || e.target.value === "") {
          setParametroCuotas(cuotasProyecto);
        }
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          setParametroCuotas(cuotasProyecto);
        }
      }
    }
  };

  const cambiarArea = (e) => {
    setAreaInterna(e.target.value);
    setInputArea(e.target.value);
    handlerInmueble("area", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      setAreaInterna(
        parseFloat(filtroInmueble[0].values.area.replace(/,/g, ".")).toFixed(2)
      );
    }
  };

  const cambiarAreaInterna = (e) => {
    setAreaInterna(e.target.value);
    setInputArea(e.target.value);
    handlerInmueble("area", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      setAreaInterna(
        parseFloat(filtroInmueble[0].values.area_m2.replace(/,/g, ".")).toFixed(
          2
        )
      );
    }
  };

  const cambiarBalcon = (e) => {
    setInputBalcon(e.target.value);
    setAreaBalcon(e.target.value);
    handlerInmueble("areabalcon", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      setAreaBalcon(
        parseFloat(
          filtroInmueble[0].values.balcon_m2.replace(/,/g, ".")
        ).toFixed(2)
      );
    }
  };

  const cambiarPatio = (e) => {
    setInputPatio(e.target.value);
    setAreaPatio(e.target.value);
    handlerInmueble("areapatio", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      setAreaPatio(
        parseFloat(
          filtroInmueble[0].values.patio_m2.replace(/,/g, ".")
        ).toFixed(2)
      );
    }
  };

  const cambiarAzotea = (e) => {
    setAreaAzotea(e.target.value);
    setInputAzotea(e.target.value);
    handlerInmueble("areaazotea", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      if (
        nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
        nombreTabla === "TOWN CENTER OFICINAS" ||
        nombreTabla === "TOWN CENTER OFICINAS T1"
      ) {
        setAreaAzotea(
          parseFloat(
            filtroInmueble[0].values.patio_azotea_m2.replace(/,/g, ".")
          ).toFixed(2)
        );
      } else {
        setAreaAzotea(
          parseFloat(
            filtroInmueble[0].values.azotea_m2.replace(/,/g, ".")
          ).toFixed(2)
        );
      }
    }
  };

  const cambiarParqueo = (e) => {
    setParqueo(e.target.value);
    setInputParqueo(e.target.value);
    handlerInmueble("parqueo", e.target.value);

    setCantParqueos(e.target.value);

    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      setParqueo(
        parseFloat(filtroInmueble[0].values.parqueo.replace(/,/g, ".")).toFixed(
          2
        )
      );
      if (
        nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
        nombreTabla === "TOWN CENTER OFICINAS" ||
        nombreTabla === "TOWN CENTER OFICINAS T1"
      ) {
        setCantParqueos(0);
      } else if (nombreTabla === "SKY VIEW TORRE 1") {
        setCantParqueos(2);
      } else if (nombreTabla === "SKY VIEW TORRE 2") {
        setCantParqueos(0);
      } else {
        setCantParqueos(0);
      }
    }
  };

  const cambiarBodega = (e) => {
    setBodega(e.target.value);
    setInputBodega(e.target.value);

    setCantBodegas(e.target.value);

    if (e.target.value === 0 || e.target.value === "") {
      if (
        nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
        nombreTabla === "TOWN CENTER OFICINAS" ||
        nombreTabla === "TOWN CENTER OFICINAS T1"
      ) {
        setCantBodegas(0.0);
      } else if (nombreTabla === "SKY VIEW TORRE 1") {
        setCantBodegas(0.0);
      } else if (nombreTabla === "SKY VIEW TORRE 2") {
        setCantBodegas(0.0);
      } else {
        setCantBodegas(0.0);
      }
    }
  };

  const cambiarPrecio = (e) => {
    setInputPrecio(e.target.value);
    handlerInmueble("areaprecio", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    setMetrosCuadradosInternos(e.target.value);
    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      setMetrosCuadradosInternos(
        parseFloat(
          filtroInmueble[0].values.preciom2.replace(/,/g, ".")
        ).toFixed(2)
      );
    }
  };

  const cambiarPrecioAreaInterna = (e) => {
    setInputPrecioArea(e.target.value);
    handlerInmueble("areaprecio", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    setMetrosCuadradosInternos(e.target.value);
    if (e.target.value === 0 || e.target.value === "") {
      const filtroInmueble = inmuebles.filter(
        (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
      );
      setMetrosCuadradosInternos(
        parseFloat(
          filtroInmueble[0].values.precio_por_m2_interno.replace(/,/g, ".")
        ).toFixed(2)
      );
    }
  };

  const cambiarPrecioBalcon = (e) => {
    setInputPrecioBalcon(e.target.value);
    setPrecioBalcon(e.target.value);
    handlerInmueble("preciobalcon", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      } else if (
        nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
        nombreTabla === "TOWN CENTER OFICINAS" ||
        nombreTabla === "TOWN CENTER OFICINAS T1"
      ) {
        if (e.target.value === 0 || e.target.value === "") {
          const filtroInmueble = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          setPrecioBalcon(
            parseFloat(
              filtroInmueble[0].values.precio_de_balcon.replace(/,/g, ".")
            ).toFixed(2)
          );
        }
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          const filtroInmueble = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          setPrecioBalcon(
            parseFloat(
              filtroInmueble[0].values.precio_de_balcon.replace(/,/g, ".")
            ).toFixed(2)
          );
        }
      }
    }
  };

  const cambiarPrecioAzotea = (e) => {
    setInputPrecioAzotea(e.target.value);
    setPrecioAzotea(e.target.value);
    handlerInmueble("precioazotea", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          const filtroInmueble = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          setPrecioAzotea(
            parseFloat(
              filtroInmueble[0].values.precio_del_patio_azotea.replace(/,/g, ".")
            ).toFixed(2)
          );
        }
      }
    }
  };

  const cambiarPrecioParqueo = (e) => {
    setInputPrecioParqueo(e.target.value);
    setPrecioParqueo(e.target.value);
    handlerInmueble("precioparqueo", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          const filtroInmueble = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          setPrecioParqueo(
            parseFloat(
              filtroInmueble[0].values.precio_de_parqueo.replace(/,/g, ".")
            ).toFixed(2)
          );
        }
      }
    }
  };

  const cambiarPrecioPatio = (e) => {
    setInputPrecioPatio(e.target.value);
    setPrecioPatio(e.target.value);
    handlerInmueble("preciopatio", e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      if (
        nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ||
        nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
        nombreTabla === "TOWN CENTER OFICINAS" ||
        nombreTabla === "TOWN CENTER OFICINAS T1"
      ) {
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          const filtroInmueble = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          setPrecioPatio(
            parseFloat(
              filtroInmueble[0].values.precio_del_patio_azotea.replace(/,/g, ".")
            ).toFixed(2)
          );
        }
      }
    }
  };

  const cambiarPrecioBodega = (e) => {
    setInputPrecioBodega(e.target.value);
    setPrecioBodega(e.target.value);

    setPersonalizado(true);
    handlerPersonalizado();

    if (e.target.value === 0 || e.target.value === "") {
      if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      } else {
        if (e.target.value === 0 || e.target.value === "") {
          const filtroInmueble = inmuebles.filter(
            (inmuebleFiltrado) => inmuebleFiltrado.id === inmuebleSeleccionado
          );
          setPrecioBodega(
            parseFloat(
              filtroInmueble[0].values.precio_bodegas.replace(/,/g, ".")
            ).toFixed(2)
          );
        }
      }
    }
  };

  return (
    <>
      <section className="flexDirection flexEnd">
        <div className="inputsCotizador columnDirection">
          <div className="selectorInmuebles">
            {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
              <p>LOTES</p>
            ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ? (
              <p>TORRE 3 - DEPTO.</p>
            ) : nombreTabla === "TOWN CENTER OFICINAS" ? (
              <p>TORRE 4 - OFICINAS</p>
            ) : nombreTabla === "TOWN CENTER OFICINAS T1" ? (
              <p>TORRE 1 - OFICINAS</p>
            ) : nombreTabla === "SKY VIEW TORRE 1" ? (
              <p>TORRE 1 - DEPTO.</p>
            ) : (
              <p>TORRE 2 - DEPTO.</p>
            )}
            <select
              onChange={opcionSeleccionada}
              name="selector_lote"
              value={inmuebleSeleccionado}
            >
              <option value="seleccionar">Seleccione una opción</option>
              {cantInmuebles > 0 ? (
                inmuebles.map((inmueble) => {
                  return nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
                    <option key={inmueble.id} value={inmueble.id}>
                      {inmueble.values.lote}
                    </option>
                  ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
                    nombreTabla === "TOWN CENTER OFICINAS" ||
                    nombreTabla === "TOWN CENTER OFICINAS T1" ? (
                    <option key={inmueble.id} value={inmueble.id}>
                      {inmueble.values.inmueble}
                    </option>
                  ) : (
                    <option key={inmueble.id} value={inmueble.id}>
                      {inmueble.values.inmueble}
                    </option>
                  );
                })
              ) : (
                <option className="advertencia">Cargando inmuebles.</option>
              )}
            </select>
          </div>
          <DatosCotizacion
            inmuebleSeleccionado={inmuebleSeleccionado}
            nombreTabla={nombreTabla}
            area={area}
            metrosCuadrados={metrosCuadrados}
            total={total}
            abono={abono}
            reserva={reserva}
            firma={firma}
            cuotas={cuotas}
            contraEntrega={contraEntrega}
            credito={credito}
            parametroReserva={parametroReserva}
            parametroFirma={parametroFirma}
            parametroCredito={parametroCredito}
            parametroCuotas={cuotasProyecto}
            parametroEntrega={parametroEntrega}
            areaAzotea={areaAzotea}
            areaPatio={areaPatio}
            areaInterna={areaInterna}
            areaBalcon={areaBalcon}
            parqueo={parqueo}
            bodega={bodega}
            metrosCuadradosInternos={metrosCuadradosInternos}
            precioBalcon={precioBalcon}
            precioPatio={precioPatio}
            precioParqueo={precioParqueo}
            precioBodega={precioBodega}
            precioAzotea={precioAzotea}
            disponibilidad={disponibilidad}
            cantParqueos={cantParqueos}
            cantBodegas={cantBodegas}
            accesos={accesos}
          />
        </div>
        {camposBloqueados === false ? (
          <div className="inputsCotizador columnDirection">
            <h2>CONDICIONES NUEVAS {nombreTabla}</h2>
            <table className="customInputs">
              {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
                <>
                  <tr>
                    <td>CAMBIAR ÁREA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarArea"
                        onChange={cambiarArea}
                        min="0"
                        value={inputArea}
                        className={inputArea != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR PRECIO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecio"
                        onChange={cambiarPrecio}
                        min="0"
                        value={inputPrecio}
                        className={inputPrecio != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <hr></hr>
                  </tr>
                  <tr>
                    <td>CAMBIAR RESERVA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarReserva"
                        onChange={cambiarReserva}
                        min="0"
                        value={inputReserva}
                        className={inputReserva != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR FIRMA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarFirma"
                        onChange={cambiarFirma}
                        min="0"
                        value={inputFirma}
                        className={inputFirma != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR GASTOS</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarGastos"
                        onChange={cambiarGastos}
                        min="0"
                        value={inputGastos}
                        className={inputGastos != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR CUOTAS</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarCuotas"
                        onChange={cambiarCuotas}
                        min="0"
                        value={inputCuotas}
                        className={inputCuotas != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR CRÉDITO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarCredito"
                        onChange={cambiarCredito}
                        min="0"
                        value={inputCredito}
                        className={inputCredito != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
                nombreTabla === "TOWN CENTER OFICINAS" || nombreTabla === "TOWN CENTER OFICINAS T1" ? (
                <>
                  <tr>
                    <td>NUEVA ÁREA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarArea"
                        onChange={cambiarAreaInterna}
                        min="0"
                        value={inputArea}
                        className={inputArea != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO ÁREA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioArea"
                        onChange={cambiarPrecioAreaInterna}
                        min="0"
                        value={inputPrecioArea}
                        className={inputPrecioArea != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR BALCÓN</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarBalcon"
                        onChange={cambiarBalcon}
                        min="0"
                        value={inputBalcon}
                        className={inputBalcon != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO BALCÓN</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioBalcon"
                        onChange={cambiarPrecioBalcon}
                        min="0"
                        value={inputPrecioBalcon}
                        className={inputPrecioBalcon != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR AZOTEA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarAzotea"
                        onChange={cambiarAzotea}
                        min="0"
                        value={inputAzotea}
                        className={inputAzotea != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO AZOTEA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioAzotea"
                        onChange={cambiarPrecioAzotea}
                        min="0"
                        value={inputPrecioAzotea}
                        className={inputPrecioAzotea != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR ÁREA BODEGA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarBodega"
                        onChange={cambiarBodega}
                        min="0.00"
                        value={inputBodega}
                        className={inputBodega != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO BODEGA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioBodega"
                        onChange={cambiarPrecioBodega}
                        min="0"
                        value={inputPrecioBodega}
                        className={inputPrecioBodega != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR PARQUEO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarParqueo"
                        onChange={cambiarParqueo}
                        min="0"
                        value={inputParqueo}
                        className={inputParqueo != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO PARQUEO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioParqueo"
                        onChange={cambiarPrecioParqueo}
                        min="0"
                        value={inputPrecioParqueo}
                        className={inputPrecioParqueo != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <hr></hr>
                  </tr>
                  <tr>
                    <td>CAMBIAR RESERVA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarReserva"
                        onChange={cambiarReserva}
                        min="0"
                        value={inputReserva}
                        className={inputReserva != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR FIRMA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarFirma"
                        onChange={cambiarFirma}
                        min="0"
                        value={inputFirma}
                        className={inputFirma != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR GASTOS</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarGastos"
                        onChange={cambiarGastos}
                        min="0"
                        value={inputGastos}
                        className={inputGastos != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR CUOTAS</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarCuotas"
                        onChange={cambiarCuotas}
                        min="0"
                        value={inputCuotas}
                        className={inputCuotas != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR CRÉDITO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarCredito"
                        onChange={cambiarCredito}
                        min="0"
                        value={inputCredito}
                        className={inputCredito != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>NUEVA ÁREA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarArea"
                        onChange={cambiarAreaInterna}
                        min="0"
                        value={inputArea}
                        className={inputArea != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO ÁREA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioArea"
                        onChange={cambiarPrecioAreaInterna}
                        min="0"
                        value={inputPrecioArea}
                        className={inputPrecioArea != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR BALCÓN</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarBalcon"
                        onChange={cambiarBalcon}
                        min="0"
                        value={inputBalcon}
                        className={inputBalcon != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO BALCÓN</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioBalcon"
                        onChange={cambiarPrecioBalcon}
                        min="0"
                        value={inputPrecioBalcon}
                        className={inputPrecioBalcon != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR PATIO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPatio"
                        onChange={cambiarPatio}
                        min="0"
                        value={inputPatio}
                        className={inputPatio != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO PATIO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioPatio"
                        onChange={cambiarPrecioPatio}
                        min="0"
                        value={inputPrecioPatio}
                        className={inputPrecioPatio != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR AZOTEA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarAzotea"
                        onChange={cambiarAzotea}
                        min="0"
                        value={inputAzotea}
                        className={inputAzotea != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO AZOTEA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioAzotea"
                        onChange={cambiarPrecioAzotea}
                        min="0"
                        value={inputPrecioAzotea}
                        className={inputPrecioAzotea != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR ÁREA BODEGA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarBodega"
                        onChange={cambiarBodega}
                        min="0.00"
                        value={inputBodega}
                        className={inputBodega != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO BODEGA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioBodega"
                        onChange={cambiarPrecioBodega}
                        min="0"
                        value={inputPrecioBodega}
                        className={inputPrecioBodega != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR PARQUEO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarParqueo"
                        onChange={cambiarParqueo}
                        min="0"
                        value={inputParqueo}
                        className={inputParqueo != 0 ? "editado" : ""}
                      />
                    </td>
                    <td>PRECIO PARQUEO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarPrecioParqueo"
                        onChange={cambiarPrecioParqueo}
                        min="0"
                        value={inputPrecioParqueo}
                        className={inputPrecioParqueo != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <hr></hr>
                  </tr>
                  <tr>
                    <td>CAMBIAR RESERVA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarReserva"
                        onChange={cambiarReserva}
                        min="0"
                        value={inputReserva}
                        className={inputReserva != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR FIRMA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarFirma"
                        onChange={cambiarFirma}
                        min="0"
                        value={inputFirma}
                        className={inputFirma != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR GASTOS</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarGastos"
                        onChange={cambiarGastos}
                        min="0"
                        value={inputGastos}
                        className={inputGastos != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR CUOTAS</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarCuotas"
                        onChange={cambiarCuotas}
                        min="0"
                        value={inputCuotas}
                        className={inputCuotas != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR CRÉDITO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarCredito"
                        onChange={cambiarCredito}
                        min="0"
                        value={inputCredito}
                        className={inputCredito != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              )}
            </table>
          </div>
        ) : (
          <div className="inputsCotizador columnDirection">
            <h2>CONDICIONES NUEVAS {nombreTabla}</h2>
            <table className="customInputs">
              {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ? (
                <></>
              ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
                nombreTabla === "TOWN CENTER OFICINAS" || nombreTabla === "TOWN CENTER OFICINAS T1" ? (
                <>
                  <tr>
                    <td>CAMBIAR PARQUEO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarParqueo"
                        onChange={cambiarParqueo}
                        min="0"
                        value={inputParqueo}
                        className={inputParqueo != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR ÁREA BODEGA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarBodega"
                        onChange={cambiarBodega}
                        min="0.00"
                        value={inputBodega}
                        className={inputBodega != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>CAMBIAR PARQUEO</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarParqueo"
                        onChange={cambiarParqueo}
                        min="0"
                        value={inputParqueo}
                        className={inputParqueo != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CAMBIAR ÁREA BODEGA</td>
                    <td>
                      <input
                        type="number"
                        name="cambiarBodega"
                        onChange={cambiarBodega}
                        min="0.00"
                        value={inputBodega}
                        className={inputBodega != 0 ? "editado" : ""}
                      />
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        )}
      </section>
      <DetalleInmueble
        inmuebles={inmuebles}
        nombreTabla={nombreTabla}
        area={areaInterna}
        metrosCuadrados={metrosCuadrados}
        total={total}
        abono={abono}
        reserva={reserva}
        firma={firma}
        cuotas={cuotas}
        contraEntrega={contraEntrega}
        credito={credito}
        parametroReserva={parametroReserva}
        parametroFirma={parametroFirma}
        parametroCredito={parametroCredito}
        parametroCuotas={cuotasProyecto}
        parametroEntrega={parametroEntrega}
        areaAzotea={areaAzotea}
        areaPatio={areaPatio}
        areaInterna={areaInterna}
        areaBalcon={areaBalcon}
        parqueo={parqueo}
        bodega={bodega}
        metrosCuadradosInternos={metrosCuadradosInternos}
        precioBalcon={precioBalcon}
        precioPatio={precioPatio}
        precioParqueo={precioParqueo}
        precioBodega={precioBodega}
        precioAzotea={precioAzotea}
        disponibilidad={disponibilidad}
        cantParqueos={cantParqueos}
        cantBodegas={cantBodegas}
      />
      <CotizacionFinal
        inmuebles={inmuebles}
        nombreTabla={nombreTabla}
        inmuebleSeleccionado={inmuebleSeleccionado}
        area={area}
        metrosCuadrados={metrosCuadrados}
        total={total}
        abono={abono}
        reserva={reserva}
        firma={firma}
        cuotas={cuotas}
        contraEntrega={contraEntrega}
        credito={credito}
        parametroReserva={parametroReserva}
        parametroFirma={parametroFirma}
        parametroCredito={parametroCredito}
        parametroCuotas={cuotasProyecto}
        parametroEntrega={parametroEntrega}
        areaAzotea={areaAzotea}
        areaPatio={areaPatio}
        areaInterna={areaInterna}
        areaBalcon={areaBalcon}
        parqueo={parqueo}
        bodega={bodega}
        metrosCuadradosInternos={metrosCuadradosInternos}
        precioBalcon={precioBalcon}
        precioPatio={precioPatio}
        precioParqueo={precioParqueo}
        precioBodega={precioBodega}
        precioAzotea={precioAzotea}
        disponibilidad={disponibilidad}
        cantParqueos={cantParqueos}
        cantBodegas={cantBodegas}
        personalizado={personalizado}
      />
    </>
  );
};

export default Cotizador;
