import React, {useEffect} from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import Login from './Login';
import ContCotizador from '../componentes/ContCotizador';



const Home = () => {
    
    const {isAuthenticated } = useAuth0()

    return (
        <>
         {isAuthenticated ? 
            <ContCotizador/>
            :
            <Login/>
         }
        </>
    )
}

export default Home
