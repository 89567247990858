
function inmueble(inmuebleSeleccionado, inmuebles, nombreTabla) {

    let data = {
        inmueblecotizado: "",
        area: 0,
        areaprecio: 0,
        disponibilidad: "",
        areaazotea: 0,
        precioazotea: 0,
        areabalcon: 0,
        preciobalcon: 0,
        parqueo: 1,
        areapatio: 0,
        preciopatio: 0,
        bodega: 0,
        preciobodega: 800,
        abono: 3000,
        preciototal: 0,
        fecha_de_cotizacion: new Date().toLocaleDateString("es", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
    }


    /*
    const [data, setdata] = useState(inmueble(inmuebleSeleccionado, inmuebles, nombreTabla));

    const handlerInmueble = (attr, value) =>{
        const obj = {[attr]: value};
        setdata(data=>{
            return {...data,
            ...obj}
        })
    }
    */

/*
    if(inmuebleSeleccionado === "seleccionar" && inmuebles.lenght > 0){
        inmuebleSeleccionado = inmuebles[0].id
    }
*/

    if (inmuebleSeleccionado !== "seleccionar") {

        let inm = inmuebles.filter(inmuebleFiltrado => inmuebleFiltrado.id === inmuebleSeleccionado)

        data.id = inmuebleSeleccionado

        data.inmueblecotizado = inm[0].values.inmueble

        data.inmueblenum = inm[0].values.inmueble

        data.proyecto = nombreTabla



        switch (nombreTabla)
        {
            case "TERRENOS SAN PATRICIO GARDENS":
                data.inmueblecotizado = "LOTE " + inm[0].values.lote
                data.inmueblenum = inm[0].values.lote
            break
            case "TOWN CENTER DEPARTAMENTOS":
                data.inmueblecotizado = "TOWN CENTER - TORRE 3 - DEPARTAMENTO N°: " + inm[0].values.inmueble
                data.inmueblenum = inm[0].values.inmueble
            break
            case "TOWN CENTER OFICINAS":
                data.inmueblecotizado = "TOWN CENTER - TORRE 4 - OFICINAS N°: " + inm[0].values.inmueble
                data.inmueblenum = inm[0].values.inmueble
            break
            case "SKY VIEW TORRE 1":
                data.inmueblecotizado = "TORRE 1 - DEPTO. " + inm[0].values.inmueble
                data.inmueblenum = inm[0].values.inmueble
            break
            case "SKY VIEW TORRE 2":
                data.inmueblecotizado = "TORRE 2 - DEPTO. " + inm[0].values.inmueble
                data.inmueblenum = inm[0].values.inmueble
            break
            case "TOWN CENTER OFICINAS T1":
                data.inmueblecotizado = "TOWN CENTER - TORRE 1 - OFICINAS N°: " + inm[0].values.inmueble
                data.inmueblenum = inm[0].values.inmueble
            break
        }

        if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {

            data.proyecto = "San Patricio Gardens - Terrenos"
            data.area = parseFloat(inm[0].values.area.replace(",", "."))
            data.areaprecio = parseFloat(inm[0].values.preciom2)
            data.disponibilidad = inm[0].values.disponibilidad.name

            data.cuotas = 12 
            data.abono = 3000
            data.porcentaje_credito = 40
            data.cantidad_de_cuotas = 12 
            data.porcentaje_contra_entrega = 50

            data.parcela = inm[0].values.parcela
            data.uso_de_suelo = inm[0].values.uso_de_suelo

            data.valorarea = data.area * data.areaprecio
            data.preciototal = data.area * data.areaprecio 

            data.siglas_proyecto = "SPG-"+inm[0].values.lote

        } else if (nombreTabla === "SKY VIEW TORRE 1" || nombreTabla === "SKY VIEW TORRE 2") {

            if(nombreTabla == "SKY VIEW TORRE 1") data.proyecto = "Sky View - Torre 1"
            else data.proyecto = "Sky View - Torre 2"
            data.area = parseFloat(inm[0].values.area_m2.replace(",", "."))
            data.areaprecio = parseFloat(inm[0].values.precio_por_m2_interno)
            data.disponibilidad = inm[0].values.disponibilidad.name

            data.areaazotea = parseFloat(inm[0].values.azotea_m2.replace(",", "."))
            data.areabalcon = parseFloat(inm[0].values.balcon_m2.replace(",", "."))
            data.cantparqueo = parseFloat(inm[0].values.parqueo)

            data.areapatio = parseFloat(inm[0].values.patio_m2.replace(",", "."))

            data.preciobalcon = parseFloat(inm[0].values.precio_de_balcon)
            data.preciopatio = parseFloat(inm[0].values.precio_del_patio_azotea)
            data.precioazotea = parseFloat(inm[0].values.precio_del_patio_azotea)
            data.precioparqueo = 16000

            data.patio = data.areapatio * data.preciopatio

            data.parqueo = data.cantparqueo * data.precioparqueo

            data.balcon = data.areabalcon * data.preciobalcon

            data.cuotas = 36
            data.abono = 5000
            data.porcentaje_credito = 30
            data.cantidad_de_cuotas = 36 
            data.porcentaje_contra_entrega = 60

            data.piso = inm[0].values.piso

            data.valorarea = data.area * data.areaprecio

            data.preciototal = (data.area * data.areaprecio) +
            (data.areabalcon * data.preciobalcon) +
            (data.areaazotea * data.precioazotea) +
            (data.cantparqueo * data.precioparqueo) +
            (data.bodega * data.preciobodega)+
            (data.areapatio * data.preciopatio);

            data.dormitorios = inm[0].values.dormitorios
                data.banos = inm[0].values.banos

            if( nombreTabla == "SKY VIEW TORRE 1" ) data.siglas_proyecto = "SV1-"+inm[0].values.inmueble
            else data.siglas_proyecto = "SV2-"+inm[0].values.inmueble

        } else if (nombreTabla === "TOWN CENTER DEPARTAMENTOS" || nombreTabla === "TOWN CENTER OFICINAS") {

            if(nombreTabla == "TOWN CENTER DEPARTAMENTOS") data.proyecto = "Town Center - Torre 3"
            else data.proyecto = "Town Center - Torre 4"
            data.area = parseFloat(inm[0].values.area_m2.replace(",", "."))
            data.areaprecio = parseFloat(inm[0].values.precio_por_m2_interno)
            data.disponibilidad = inm[0].values.disponibilidad.name

            data.areaazotea = parseFloat(inm[0].values.patio_azotea_m2.replace(",", "."))
            data.areabalcon = parseFloat(inm[0].values.balcon_m2.replace(",", "."))
            data.cantparqueo = parseFloat(inm[0].values.parqueo)
            data.areapatio = parseFloat(inm[0].values.patio_azotea_m2)

            data.preciobalcon = parseFloat(inm[0].values.precio_del_balcon)
            data.preciopatio = parseFloat(inm[0].values.precio_del_patio_azotea)
            data.precioazotea = parseFloat(inm[0].values.precio_del_patio_azotea)
            data.precioparqueo = 16000

            data.patio = data.areapatio * data.preciopatio

            data.parqueo = data.cantparqueo * data.precioparqueo

            data.balcon = data.areabalcon * data.preciobalcon

            data.cuota_al_banco = inm[0].values.cuota_al_banco_0

            data.cuotas = 36
            data.abono = 5000
            data.porcentaje_credito = 30
            data.cantidad_de_cuotas = 36 
            data.porcentaje_contra_entrega = 60

            data.piso = inm[0].values.piso

            data.valorarea = data.area * data.areaprecio
            data.preciototal = data.area * data.areaprecio +
            data.areabalcon * data.preciobalcon +
            data.areaazotea * data.precioazotea +
            data.parqueo * data.precioparqueo +
            data.bodega * data.preciobodega+
            data.areapatio * data.preciopatio;

            if( nombreTabla == "TOWN CENTER DEPARTAMENTOS" ) data.siglas_proyecto = "TC3-"+inm[0].values.inmueble
            else data.siglas_proyecto = "TC4-"+inm[0].values.inmueble

            if( nombreTabla == "TOWN CENTER DEPARTAMENTOS" ){

                data.dormitorios = inm[0].values.dormitorios
                data.banos = inm[0].values.banos

            }else{
                data.banos = inm[0].values.banos
            }
        } else if ( nombreTabla === "TOWN CENTER OFICINAS T1") {

            data.proyecto = "Town Center - Torre 1"
            data.siglas_proyecto = "TC1-"+inm[0].values.inmueble
            data.area = parseFloat(inm[0].values.area_m2.replace(",", "."))
            data.areaprecio = parseFloat(inm[0].values.precio_por_m2_interno)
            data.disponibilidad = inm[0].values.disponibilidad.name

            //data.areaazotea = parseFloat(inm[0].values.patio_azotea_m2.replace(",", "."))
            data.areabalcon = parseFloat(inm[0].values.balcon_m2.replace(",", "."))
            //data.cantparqueo = parseFloat(inm[0].values.parqueo)
            //data.areapatio = parseFloat(inm[0].values.patio_azotea_m2)

            data.preciobalcon = parseFloat(inm[0].values.precio_del_balcon)
            //data.preciopatio = parseFloat(inm[0].values.precio_del_patio_azotea)
            //data.precioazotea = parseFloat(inm[0].values.precio_del_patio_azotea)
            data.precioparqueo = 16000

            //data.patio = data.areapatio * data.preciopatio

            data.parqueo = data.cantparqueo * data.precioparqueo

            data.balcon = data.areabalcon * data.preciobalcon

            data.cuota_al_banco = inm[0].values.cuota_al_banco_0

            data.cuotas = 36
            data.abono = 5000
            data.porcentaje_credito = 30
            data.cantidad_de_cuotas = 36 
            data.porcentaje_contra_entrega = 60

            data.piso = inm[0].values.piso

            data.valorarea = data.area * data.areaprecio
            data.preciototal = data.area * data.areaprecio 

            data.banos = 0

        }

        
        
        if(inm[0].values.plano) data.plano = inm[0].values.plano.url 
       

      data.reserva = (data.preciototal * 1) / 100
      data.cuotas = ((((data.preciototal) * data.porcentaje_credito) / 100) + data.abono) / data.cantidad_de_cuotas
      data.credito = (((data.preciototal) * data.porcentaje_credito) / 100) + data.abono
      data.contraentrega = (((data.preciototal) * data.porcentaje_contra_entrega) / 100)
      data.firmacontrato = ((data.preciototal) * 9) / 100


      data.porcentaje_reserva = 1
      data.porcentaje_firma = 9


      //console.log("elegido", data)

    } /*else {

        data.inmueblecotizado = "SELECCIONAR"
        data.area = 0
        data.areaprecio = 0
        data.disponibilidad = ""
        data.areaazotea = 0
        data.precioazotea = 0
        data.areabalcon = 0
        data.preciobalcon = 0
        data.parqueo = 0
        data.areapatio = 0
        data.preciopatio = 0
    }*/
    
    return data

}

export default inmueble