import React, { useEffect, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import InmueblesContext from '../contextos/InmueblesContext';

const Header = () => {
  const { logout, loginWithRedirect, user, isAuthenticated } = useAuth0();
  const { setNombreTabla, nombreTabla, accesos } = useContext(InmueblesContext);
  const [logoProyecto, setLogoProyecto] = useState("");
  const [datosUsuario, setDatosUsuario] = useState({});

  useEffect(() => {
    if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      setLogoProyecto("/images/SPG.png");
    } else if (
      nombreTabla === "TOWN CENTER DEPARTAMENTOS" ||
      nombreTabla === "TOWN CENTER OFICINAS" ||
      nombreTabla === "TOWN CENTER OFICINAS T1"
    ) {
      setLogoProyecto("/images/TC.png");
    } else {
      setLogoProyecto("/images/SVT.png");
    }
  }, [nombreTabla]);

  useEffect(() => {
    if (isAuthenticated && user && accesos.length > 0) {
      const usuarioAcceso = accesos.find(acceso => acceso.values.correo === user.email);
      if (usuarioAcceso) {
        setDatosUsuario(usuarioAcceso.values);
      }
    }
  }, [isAuthenticated, user, accesos]);

  //console.log("Accesos", accesos);
  //console.log("User", user);

  return (
    <header className="App-header">
      <img src="/images/SP.png" className="App-logo" alt="logo" width="150" />
      {isAuthenticated ? (
        <>
          <img src={logoProyecto} className="App-logo-proyecto" alt="logo-proyecto" width="150" />
          <div className='login'>
            <div className='user_data'>
              <img src={user.picture} alt={user.name} />
              <div className='float'>
                <h4>{user.name}</h4>
                <p>{user.email}</p>
                <p>{datosUsuario.telefono ? datosUsuario.telefono : "099 933 1700"}</p>
                <button onClick={() => logout({ returnTo: window.location.origin })}>Cerrar Sesión</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='login'>
          <button className="acceder" onClick={() => loginWithRedirect()}>Iniciar Sesión</button>
        </div>
      )}
    </header>
  );
};

export default Header;
