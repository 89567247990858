import React, {useContext, useState, useEffect} from 'react'
import InmueblesContext from '../contextos/InmueblesContext'

const DetalleInmueble = ({ inmuebles, nombreTabla,cantParqueos, cantBodegas, area, metrosCuadrados, total, reserva ,abono, firma, contraEntrega, credito, parametroReserva, parametroFirma, parametroCredito, parametroCuotas, parametroEntrega, disponibilidad, areaAzotea, areaPatio, areaInterna, areaBalcon, parqueo, bodega, metrosCuadradosInternos, precioBalcon, precioPatio, precioParqueo, precioBodega, precioAzotea}) => {
    
    const {inmuebleData, inmuebleSeleccionado, abrirDetalle, inmuebleActivo, setInmuebleActivo, cerrarDetalle, formCotizacion , setFormCotizacion, date } = useContext(InmueblesContext)
    
    const [detalleInmuebleSeleccionado, setDetalleInmuebleSeleccionado] = useState([])


    useEffect(() => {

        if (inmuebleActivo === true) { 
            let filtroInmueble = inmuebles.filter(inmueble => inmueble.id === inmuebleSeleccionado)
            console.log(inmuebles, inmuebleSeleccionado)
            setDetalleInmuebleSeleccionado(filtroInmueble)
        }


        if (formCotizacion === true) {
            const script = document.createElement('script');
            script.src='https://js.hsforms.net/forms/v2.js';
            document.body.appendChild(script);

            const submitted = () => {
                const firstname = document.querySelector(
                    "#hubspotForm input[name='firstname']"
                  ).value,
                  lastname = document.querySelector(
                    "#hubspotForm input[name='lastname']"
                  ).value,
                  phone = document.querySelector(
                    "#hubspotForm input[name='phone']"
                  ).value,
                  ciudad = document.querySelector(
                    "#hubspotForm select[name='ciudad_']"
                  ).value,
                  message = document.querySelector(
                    "#hubspotForm textarea[name='message']"
                  ).value,
                  email = document.querySelector(
                    "#hubspotForm input[name='email']"
                  ).value;
        
                console.log("email", email);
        
                const myHeaders = new Headers();
                myHeaders.append(
                  "Authorization",
                  "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY
                );
                myHeaders.append("Content-Type", "application/json");
        
                const raw = JSON.stringify({
                  email: email,
                  firstname: firstname,
                  lastname: lastname,
                  phone: phone,
                  ciudad: ciudad,
                  message: message,
                  data: inmuebleData,
                });
                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };
        
                fetch(`${process.env.REACT_APP_SERVER_PORT}`, requestOptions)
                  .then((response) => response.text())
                  .then((result) => console.log(result))
                  .catch((error) => console.log("error", error));
              };
        
            
            script.addEventListener('load', () => {
                if (window.hbspt) {
                    window.hbspt.forms.create({
                        portalId: '20027847',
                        formId: '3767a9b7-5f4a-4a72-9723-005c6b573c48',
                        target: '#hubspotForm',
                        inlineMessage: 'Contacto creado correctamente. <br> <button id="imprimir" onclick="window.print()">Imprimir cotización</button>',
                        onFormSubmit: submitted,
                    })
                }
            });
        }

    }, [inmuebleActivo, inmuebleSeleccionado, formCotizacion])


    const [cuotas, setCuotas] = useState(parametroCuotas)
    const [abonoGastosLegales, setAbonoGastosLegales] = useState(3000)
    
    const realizarCotizacion = () => {
        console.log("Realizo Cotización")
        setFormCotizacion(true)
    }


    useEffect(() => {
     if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS"){
        setCuotas(parametroCuotas)
        setAbonoGastosLegales(3000)
     } else {
        setCuotas(parametroCuotas)
        setAbonoGastosLegales(5000)
     }
    }, [nombreTabla])


    return (
        <aside className={inmuebleActivo === true ? 'mostrar_detalle' : 'cerrar_detalle'}>
        { inmuebleSeleccionado != "seleccionar" ?
            <div>
            {
                formCotizacion === false ?
                detalleInmuebleSeleccionado.map(inmueble => {
                    return (
                        <div key={inmueble.id}>
                        {nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ?
                            <>
                                <header>
                                    <h3>LOTE N°{inmueble.values.lote}</h3>
                                </header>
                                <div>
                                    <p className={disponibilidad === "Disponible" ? "green" : disponibilidad === "Vendido" ? "rojo" : "gray"}>{disponibilidad}</p>
                                    <p>Área: {parseFloat(area?.replace(/,/g, ".")).toFixed(2)}m²</p>
                                    <p>Precio: {parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Total: {(parseFloat((area)?.replace(/,/g, ".")) * parseFloat((metrosCuadradosInternos)?.replace(/,/g, "."))).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Reserva: {(parseFloat((area)?.replace(/,/g, ".")) * parseFloat((metrosCuadradosInternos)?.replace(/,/g, ".")) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Cuotas mensuales: {(((parseFloat(((area)?.replace(/,/g, ".")) * parseFloat((metrosCuadradosInternos)?.replace(/,/g, ".")) / 100) * 40) + parseFloat(abono)) / cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p><button className='realizarCotizacion' onClick={realizarCotizacion}>Realizar cotización</button></p>
                                </div>
                            </>
                            : nombreTabla === "TOWN CENTER DEPARTAMENTOS" || nombreTabla === "TOWN CENTER OFICINAS" || nombreTabla === "TOWN CENTER OFICINAS T1" ?
                            <>
                                <h3>PISO {inmueble.values.piso} {nombreTabla === "TOWN CENTER DEPARTAMENTOS" ? "DEPTO." : "OFICINA"} {inmueble.values.inmueble}</h3>
                                <div>
                                    <p className={disponibilidad === "Disponible" ? "green" : disponibilidad === "Vendido" ? "rojo" : "gray"}>{disponibilidad}</p>
                                    <p>Área: {parseFloat(area?.replace(/,/g, ".")).toFixed(2)}m²</p>
                                    <p>Precio: {parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Total: {((parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")) * parseFloat(area?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaBalcon?.replace(/,/g, ".")) * parseFloat(precioBalcon?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaAzotea?.replace(/,/g, ".")) * parseFloat(precioAzotea?.replace(/,/g, ".")).toFixed(2)) +  (parseFloat(cantBodegas).toFixed(2) * parseFloat(precioBodega).toFixed(2)) + (parseFloat(cantParqueos) * parseFloat(precioParqueo))).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Reserva: {((((parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")) * parseFloat(area?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaBalcon?.replace(/,/g, ".")) * parseFloat(precioBalcon?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaAzotea?.replace(/,/g, ".")) * parseFloat(precioAzotea?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(cantBodegas).toFixed(2) * parseFloat(precioBodega).toFixed(2)) + (parseFloat(cantParqueos) * parseFloat(precioParqueo))) * 1 ) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Cuotas mensuales: {((((((parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")) * parseFloat(area?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaBalcon?.replace(/,/g, ".")) * parseFloat(precioBalcon?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaAzotea?.replace(/,/g, ".")) * parseFloat(precioAzotea?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(cantBodegas).toFixed(2) * parseFloat(precioBodega).toFixed(2)) + (parseFloat(cantParqueos) * parseFloat(precioParqueo))) * 30 ) / 100) + abono) / cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p><button className='realizarCotizacion' onClick={realizarCotizacion}>Realizar cotización</button></p>
                                </div>
                            </>
                            :
                            <>
                                <h3>PISO {inmueble.values.piso} DEPTO. {inmueble.values.inmueble}</h3>
                                <div>
                                    <p className={inmueble.values.disponibilidad.name === "Disponible" ? "green" : disponibilidad === "Vendido" ? "rojo" : "gray"}>{disponibilidad}</p>
                                    <p>Área: {parseFloat(area?.replace(/,/g, ".")).toFixed(2)}m²</p>
                                    <p>Precio: {parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Total: {((parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")) * parseFloat(area?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaAzotea?.replace(/,/g, ".")) * parseFloat(precioAzotea?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(precioAzotea?.replace(/,/g, ".")) * parseFloat(areaPatio?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(precioBalcon?.replace(/,/g, ".")) * parseFloat(areaBalcon?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(cantBodegas).toFixed(2) * parseFloat(precioBodega).toFixed(2)) + (parseFloat(cantParqueos) * parseFloat(precioParqueo))).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Reserva: {((((parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")) * parseFloat(area?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaAzotea?.replace(/,/g, ".")) * parseFloat(precioAzotea?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(precioAzotea?.replace(/,/g, ".")) * parseFloat(areaPatio?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaBalcon?.replace(/,/g, ".")) * parseFloat(precioBalcon?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(cantBodegas).toFixed(2) * parseFloat(precioBodega).toFixed(2)) + (parseFloat(cantParqueos) * parseFloat(precioParqueo))) * 1 ) / 100).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p>Cuotas mensuales: {((((((parseFloat(metrosCuadradosInternos?.replace(/,/g, ".")) * parseFloat(area?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaAzotea?.replace(/,/g, ".")) * parseFloat(precioAzotea?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaPatio?.replace(/,/g, ".")) * parseFloat(precioPatio?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(areaBalcon?.replace(/,/g, ".")) * parseFloat(precioBalcon?.replace(/,/g, ".")).toFixed(2)) + (parseFloat(cantBodegas).toFixed(2) * parseFloat(precioBodega).toFixed(2)) + (parseFloat(cantParqueos) * parseFloat(precioParqueo))) * 30 ) / 100) + abono) / cuotas).toLocaleString('en', {style: 'currency',currency: 'USD'})}</p>
                                    <p><button className='realizarCotizacion' onClick={realizarCotizacion}>Realizar cotización</button></p>
                                </div>
                            </>
                        }
                        </div>
                    )
                })
                :
                <div>
                    <header>
                    {detalleInmuebleSeleccionado.map(inmueble => {
                        return (
                        nombreTabla === "TERRENOS SAN PATRICIO GARDENS" ?
                                <>
                                    <h3>COTIZACIÓN SAN PATRICIO GARDENS LOTE N°{inmueble.values.lote}</h3>
                                </>
                                : nombreTabla === "TOWN CENTER DEPARTAMENTOS" || nombreTabla === "TOWN CENTER OFICINAS"  || nombreTabla === "TOWN CENTER OFICINAS T1"?
                                <>
                                    <h3>COTIZACIÓN TOWN CENTER PISO {inmueble.values.piso} {nombreTabla === "TOWN CENTER DEPARTAMENTOS" ? "DEPTO." : "OFICINA"} {inmueble.values.inmueble}</h3>
                                </>
                                :
                                <>
                                    <h3>COTIZACIÓN SKY VIEW PISO {inmueble.values.piso} DEPTO. {inmueble.values.inmueble}</h3>
                                </>
                        )
                    })}
                    </header>
                </div>
            }
            {
                formCotizacion === false ?
                <>
                    <footer>
                        <a href="https://app.hubspot.com/contacts/20027847/objects/0-1/views/all/list" target="_blank" >Contactos Hubspot</a>
                        <a href="https://app.hubspot.com/contacts/20027847/objects/0-3/views/all/list" target="_blank" >Negocios Hubspot</a>
                    </footer>
                    <button className='cerrar' onClick={cerrarDetalle}>X</button>
                </>
                :
                <>
                    <div id="hubspotForm"></div>
                    <footer>
                        <a href="https://app.hubspot.com/contacts/20027847/objects/0-1/views/all/list" target="_blank" >Contactos Hubspot</a>
                        <a href="https://app.hubspot.com/contacts/20027847/objects/0-3/views/all/list" target="_blank" >Negocios Hubspot</a>
                    </footer>
                    <button className='cerrar' onClick={() => setFormCotizacion(false)}>&#60;</button>
                </>
            }
            </div>
            :
            <>
                
            </>
        }
        </aside>
    )
}

export default DetalleInmueble
