import React, { useEffect, useState } from "react";
import InmueblesContext from "./InmueblesContext";
import inmueble from "../helpers/inmueble";

const InmueblesState = ({ children }) => {
  const url = `${process.env.REACT_APP_SERVER_PORT}/logs-app`;
  const authToken = `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`;

  const [tablas, setTablas] = useState([]);
  const [cantTablas, setCantTablas] = useState(0);
  const [nombreTabla, setNombreTabla] = useState("TERRENOS SAN PATRICIO GARDENS");
  const [botonesTablas, setBotonesTablas] = useState([]);
  const [idTablas, setIdTablas] = useState("5387439");
  const [columnas, setColumnas] = useState([]);
  const [inmuebles, setInmuebles] = useState([]);
  const [cantInmuebles, setCantInmuebles] = useState(0);
  const [activar, setActivar] = useState(false);
  const [Backups, setBackups] = useState(false);
  const [idAccesos, setIdAccesos] = useState("");
  const [accesos, setAccesos] = useState([]);
  const [inmuebleSeleccionado, setInmuebleSeleccionado] = useState("seleccionar");
  const [inmuebleActivo, setInmuebleActivo] = useState(false);
  const [formCotizacion, setFormCotizacion] = useState(false);
  const [inputActivo, setInputActivo] = useState(false);
  const [parametrosProyectos, setParametrosProyectos] = useState({});
  const [inmuebleData, setInmuebleData] = useState(
    inmueble(inmuebleSeleccionado, inmuebles, nombreTabla)
  );
  const [cuotas, setCuotas] = useState(0); // Nueva variable de estado para cuotas

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const abrirDetalle = (e) => {
    console.log(e.target.value);
    setInmuebleActivo(!inmuebleActivo);
    setInmuebleSeleccionado(e.target.value);
    console.log("inmuebleSeleccionado", e.target.value);
  };

  const cerrarDetalle = (e) => {
    console.log(e.target.value);
    setInmuebleActivo(false);
    document.querySelectorAll(".inventario tr td input[type='checkbox']").forEach((input) => {
      input.checked = false;
    });
    setInmuebleSeleccionado("seleccionar");
    console.log("Limpio los inmuebles y proyectos");
  };

  const realizarCotizacion = () => {
    console.log("Realizo Cotización");
    setFormCotizacion(true);
  };

  const handlerInmueble = (attr, value) => {
    console.log("cambia", attr, value);
    const obj = { [attr]: value };
    setInmuebleData((inmuebleData) => {
      return { ...inmuebleData, ...obj };
    });
  };

  useEffect(() => {
    const fetchTablas = async () => {
      try {
        const response = await fetch(`${url}/inmuebles`, {
          method: "GET",
          headers: { authorization: authToken },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setTablas(result.results);
        setCantTablas(result.results.length);

        if (result.results.length !== 0) {
          const obtengoTablasAccesos = result.results.filter((TablasAccesos) =>
            TablasAccesos.label.includes("Accesos")
          );

          if (obtengoTablasAccesos.length > 0) {
            setIdAccesos(obtengoTablasAccesos[0].id);
          }

          const tablasInmuebles = result.results.filter(
            (TablasParametros) =>
              ["5391733", "5412938", "5387440", "5387439", "5412967", "7066681"].includes(
                TablasParametros.id
              )
          );

          tablasInmuebles.sort((a, b) => a.name.localeCompare(b.name));
          setBotonesTablas(tablasInmuebles);
          setIdTablas(tablasInmuebles[0].id);
          setNombreTabla(tablasInmuebles[0].label.toUpperCase());

          tablasInmuebles[0].columns = tablasInmuebles[0].columns.filter((element) => {
            if (tablasInmuebles[0].label.toUpperCase() === "TERRENOS SAN PATRICIO GARDENS") {
              return element.name !== "n_dorm" && element.name !== "parqueo";
            } else {
              return (
                element.name !== "n_dorm" &&
                element.name !== "parqueo" &&
                element.name !== "precio_del_bien"
              );
            }
          });

          setColumnas(tablasInmuebles[0].columns);
        }
      } catch (error) {
        console.error("Error fetching tablas:", error);
      }
    };

    fetchTablas();
  }, [cantTablas]);

  useEffect(() => {
    const fetchInmuebles = async () => {
      try {
        const response = await fetch(`${url}/inmuebles/idtablas?idTablas=${idTablas}`, {
          method: "GET",
          headers: { authorization: authToken },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setCantInmuebles(result.total);
        setInmuebles(result.results);
        console.log("inmuebles", result.results);
      } catch (error) {
        console.error("Error fetching inmuebles:", error);
      }
    };

    fetchInmuebles();
  }, [idTablas]);

  useEffect(() => {
    if (idAccesos) {
      const fetchAccesos = async () => {
        try {
          let url_rowsAccesos = `${url}/access?idAccesos=${idAccesos}`;
          let requestRowsAccesos = {
            method: "GET",
            headers: {
              authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
            },
          };

          let response = await fetch(url_rowsAccesos, requestRowsAccesos);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          let result = await response.json();
          setAccesos(result.results);
        } catch (error) {
          console.error("Error fetching accesos:", error);
        }
      };

      fetchAccesos();
    }
  }, [idAccesos]);

  useEffect(() => {
    setInmuebleData(inmueble(inmuebleSeleccionado, inmuebles, nombreTabla));
  }, [inmuebleSeleccionado]);

  useEffect(() => {
    const fetchParametrosProyectos = async () => {
      try {
        const response = await fetch(`${url}/parametros`, {
          method: "GET",
          headers: { authorization: authToken },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        const proyectos = {};

        for (let item in result) {
          const { name, ...data } = result[item].values;
          proyectos[name] = data;
        }

        console.log("ESTOS SON TODOS LOS PROYECTOS Y SUS PARÁMETROS", proyectos);
        setParametrosProyectos(proyectos);
      } catch (error) {
        console.log("NO SE PUDIERON CONSEGUIR LOS PROYECTOS Y SUS PARÁMETROS", error);
      }
    };

    fetchParametrosProyectos();
  }, []);

  useEffect(() => {
    if (parametrosProyectos[nombreTabla]) {
      setCuotas(parametrosProyectos[nombreTabla].cuotas);
    }
  }, [parametrosProyectos, nombreTabla]);

  const obtenerProyecto = (e) => {
    setIdTablas(e.target.value);
    setNombreTabla(e.target.getAttribute("label").toUpperCase());
    let columnasProyecto = botonesTablas.filter(
      (columnasPorProyecto) => columnasPorProyecto.id === e.target.value
    );
    columnasProyecto[0].columns = columnasProyecto[0].columns.filter((element) => {
      if (e.target.getAttribute("label").toUpperCase() === "TERRENOS SAN PATRICIO GARDENS") {
        return element.name !== "n_dorm" && element.name !== "parqueo";
      } else {
        return (
          element.name !== "n_dorm" &&
          element.name !== "parqueo" &&
          element.name !== "precio_del_bien"
        );
      }
    });
    setColumnas(columnasProyecto[0].columns);
    console.log(nombreTabla, e.target.getAttribute("label").toUpperCase());
  };

  const activarPestaña = () => {
    setActivar(!activar);
  };

  const mostrarBackups = () => {
    setBackups(!Backups);
  };

  return (
    <InmueblesContext.Provider
      value={{
        inmuebleData,
        handlerInmueble,
        abrirDetalle,
        inmuebleActivo,
        setInmuebleActivo,
        cerrarDetalle,
        inmuebleSeleccionado,
        setInmuebleSeleccionado,
        formCotizacion,
        setFormCotizacion,
        date,
        setNombreTabla,
        botonesTablas,
        cantTablas,
        activar,
        activarPestaña,
        cantInmuebles,
        inmuebles,
        columnas,
        nombreTabla,
        setInmuebles,
        accesos,
        obtenerProyecto,
        mostrarBackups,
        Backups,
        parametrosProyectos,
        cuotas, // Agregamos cuotas al contexto
      }}
    >
      {children}
    </InmueblesContext.Provider>
  );
};

export default InmueblesState;
