import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";



const Login = () => {
        

    const {loginWithRedirect} = useAuth0()
    

    return (
        <div className='acceder'>
            <p>Por favor, inicia sesión para acceder al cotizador.</p>
            <button className="acceder" onClick={() => loginWithRedirect()}>Acceder</button>
        </div>
    )
}

export default Login
