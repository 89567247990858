import React, { useState, useEffect, useContext } from "react";
import InmueblesContext from "../contextos/InmueblesContext";
import { default as inmueblehelper } from "../helpers/inmueble";

const Filas = () => {

  const {
    inmuebles,
    cantInmuebles,
    nombreTabla,
    abrirDetalle,
    inputActivo,
    setInputActivo,
  } = useContext(InmueblesContext);

  const [cuotas, setCuotas] = useState(12);
  const [abonoGastosLegales, setAbonoGastosLegales] = useState(3000);

  useEffect(() => {
    if (nombreTabla === "TERRENOS SAN PATRICIO GARDENS") {
      setCuotas(12);
      setAbonoGastosLegales(3000);
    } else {
      setCuotas(36);
      setAbonoGastosLegales(5000);
    }
  }, [nombreTabla]);

  return (
    <>
      {cantInmuebles > 0 ? (
        (inmuebles ?? []).map((Data) => {

          //console.log(Data.id, inmuebles, nombreTabla)

          let inmueble = {}

          try {
            inmueble = inmueblehelper(Data.id, inmuebles, nombreTabla);

            //console.log("inmueble helper", inmueble)
          }
          catch (e) {
            console.log(e)
          }

          return nombreTabla === "TERRENOS SAN PATRICIO GARDENS" && inmueble ? (
            <tr key={inmueble.id} id={inmueble.id}>
              <td>
                <input
                  type="checkbox"
                  onChange={abrirDetalle}
                  value={inmueble.id}
                />
              </td>
              <td id="no">{inmueble.inmueblenum}</td>
              <td id="parcela">{inmueble.parcela}</td>
              <td id="uso_de_suelo">{inmueble.uso_de_suelo}</td>
              <td>{inmueble.inmueblenum}</td>
              <td>
                {/*Area*/}
                {parseFloat(inmueble.area).toFixed(
                  2
                )}
                m²
              </td>

              <td
                className={
                  inmueble.disponibilidad === "Disponible"
                    ? "green"
                    : inmueble.disponibilidad === "Vendido"
                      ? "rojo"
                      : "gray"
                }
              >
                {/*Disponibilidad*/}
                {inmueble.disponibilidad}
              </td>

              <td id="preciom2">
                {/*Precio m²*/}
                {parseFloat(
                  inmueble.areaprecio
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="precio_total">
                {/*Total*/}
                {(parseFloat(
                  inmueble.preciototal)
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="abono_a_gastos_legales">
                {/*Abono a Gastos Legales*/}
                {parseFloat(
                  inmueble.abono_a_gastos_legales
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="reserva">
                {/*Reserva - total * 1% / 100*/}
                {(
                  parseFloat(inmueble.reserva)
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="firma_de_contrato">
                {/*Firma - total * 9% / 100*/}
                {(
                  parseFloat(inmueble.firmacontrato)
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="entrada">
                {/*Crédito directo - Entrada - total * 40% / 100 + Abono*/}
                {(
                  parseFloat(inmueble.preciototal * 0.4 / 100)
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="cuotas">
                {/*Cuotas Mensuales - total * 40% / 100 + Abono a Gastos Legales / Cuotas*/}
                {(
                  parseFloat(inmueble.cuotas)
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td>
                {/*Contra Entrega - Total 50% / 100*/}
                {(
                  parseFloat(inmueble.contraentrega)
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="cuota_al_banco">{inmueble.cuota_al_banco}</td>

                <td id="plano">
                {inmueble.plano ? (
                    <a href={inmueble.plano} target="_blank" > VER PLANO </a>
                ) : null}
                </td>

            </tr>
          ) : nombreTabla === "TOWN CENTER DEPARTAMENTOS" && inmueble ||
            (nombreTabla === "TOWN CENTER OFICINAS" || nombreTabla === "TOWN CENTER OFICINAS T1") && inmueble ? (
            <tr key={inmueble.id} id={inmueble.id}>
              <td>
                <input
                  type="checkbox"
                  onChange={abrirDetalle}
                  value={inmueble.id}
                />
              </td>
              <td id="name">{inmueble.inmueblecotizado}</td>
              <td>{inmueble.piso}</td>
              <td>{inmueble.inmueblenum}</td>

              <td
                className={
                  inmueble.disponibilidad === "Disponible"
                    ? "green"
                    : inmueble.disponibilidad === "Vendido"
                      ? "rojo"
                      : "gray"
                }
              >
                {/*Disponibilidad*/}
                {inmueble.disponibilidad}
              </td>

              <td>
                {/*Area Interna*/}
                {parseFloat(
                  inmueble.area
                ).toFixed(2)}
                m²
              </td>
              <td>
                {/*Balcón*/}
                {parseFloat(
                  inmueble.areabalcon
                ).toFixed(2)}
                m²
              </td>
              <td>
                {parseFloat(
                  inmueble.areaazotea
                ).toFixed(2)}
                m²
              </td>

              <td>
                {/*m² Totales*/}
                {(
                  parseFloat(inmueble.areabalcon) +
                  parseFloat(inmueble.area)
                ).toFixed(2)}
                m²
              </td>

              <td id="precio_por_m2_interno">
                {/*Precio m² Interno*/}
                {parseFloat(
                  inmueble.areaprecio
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td id="precio_del_patio_azotea">
                {/*Precio Patio/Azotea*/}
                {parseFloat(
                  inmueble.precioazotea
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td id="precio_bodegas">
                {/*Precio m² Bodegas*/}
                {parseFloat(
                  inmueble.preciobodega
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td id="precio_de_balcon">
                {/*Precio m² Balcón*/}
                {parseFloat(
                  inmueble.preciobalcon
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td id="precio_de_parqueo">
                {/*Precio m² Parqueo*/}
                {parseFloat(
                  inmueble.precioparqueo
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td>
                {/*Precio Total*/}
                {parseFloat(
                  inmueble.preciototal
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="abono_a_gastos_legales">
                {/*Abono a Gastos Legales*/}
                {parseFloat(
                  inmueble.abono
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="reserva">
                {/*Reserva - total * 1% / 100*/}
                {parseFloat(
                  inmueble.reserva
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="firma_de_contrato">
                {/*Firma - total * 9% / 100*/}
                {parseFloat(
                  inmueble.firmacontrato
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="entrada">
                {/*Entrada 30% */}
                {parseFloat(
                  (inmueble.area * 30) / 100 + inmueble.abono
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="cuotas">
                {/*Cuotas Mensuales 36*/}
                {parseFloat(
                  inmueble.cuotas
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td>
                {/*Contra Entrega - Total 60% / 100*/}
                {parseFloat(
                  inmueble.contraentrega
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="cuota_al_banco">
                {/*Cuota al Banco*/}
                {inmueble.cuota_al_banco}
              </td>

              {nombreTabla === "TOWN CENTER DEPARTAMENTOS" ? (

                <td id="dormitorios">
                  {/*Dormitorios*/}
                  {inmueble.dormitorios}
                </td>

              ) : (
                <></>
              )}


              <td id="banos">
                {/*Banos*/}
                {inmueble.banos}
              </td>

             
                <td id="plano">
                {inmueble.plano ? (
                    <a href={inmueble.plano} target="_blank" > VER PLANO </a>
                ) : null}
                </td>
      
            </tr>
          ) : (
            <tr key={inmueble.id} id={inmueble.id}>
              <td>
                <input
                  type="checkbox"
                  onChange={abrirDetalle}
                  value={inmueble.id}
                />
              </td>
              <td id="name">{inmueble.inmueblecotizado}</td>
              <td>{inmueble.piso}</td>
              <td>{inmueble.inmueblenum}</td>

              <td
                className={
                  inmueble.disponibilidad === "Disponible"
                    ? "green"
                    : inmueble.disponibilidad === "Vendido"
                      ? "rojo"
                      : "gray"
                }
              >
                {/*Disponibilidad*/}
                {inmueble.disponibilidad}
              </td>

              <td id="parqueo">
                {/*Parqueo*/}
                {(
                  parseFloat(inmueble.parqueo) *
                  parseFloat(inmueble.precioparqueo)
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td>
                {/*Area Interna*/}
                {parseFloat(
                  inmueble.area
                ).toFixed(2)}
                m²
              </td>
              <td>
                {/*Balcón*/}
                {parseFloat(
                  inmueble.areabalcon
                ).toFixed(2)}
                m²
              </td>
              <td>
                {/*Patio*/}
                {parseFloat(
                  inmueble.areapatio
                ).toFixed(2)}
                m²
              </td>
              <td>
                {/*Azotea*/}
                {parseFloat(
                  inmueble.areaazotea
                ).toFixed(2)}
                m²
              </td>

              <td>
                {/*m² Totales*/}
                {(
                  parseFloat(inmueble.areapatio) +
                  parseFloat(inmueble.areabalcon) +
                  parseFloat(inmueble.area)
                ).toFixed(2)}
                m²
              </td>

              <td id="precio_parqueo">
                {/*Parqueo*/}
                {parseFloat(inmueble.precioparqueo).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="precio_por_m2_interno">
                {/*Precio m² Interno*/}
                {parseFloat(
                  inmueble.areaprecio
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td id="precio_de_balcon">
                {/*Precio m² Balcón*/}
                {parseFloat(
                  inmueble.preciobalcon
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td id="precio_del_patio_azotea">
                {/*Precio m² Patio*/}
                {parseFloat(
                  inmueble.preciopatio
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td id="precio_bodegas">
                {/*Precio m² Bodegas*/}
                {parseFloat(
                  inmueble.preciobodega
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>
              <td>
                {/*Precio Total*/}
                {parseFloat(
                  inmueble.preciototal
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="reserva">
                {/*Reserva - total * 1% / 100*/}
                {parseFloat(
                  inmueble.reserva
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="abono_a_gastos_legales">
                {/*Abono a Gastos Legales*/}
                {parseFloat(
                  inmueble.abono

                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="firma_de_contrato">
                {/*Firma - total * 9% / 100*/}
                {parseFloat(
                  inmueble.firmacontrato
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="entrada">
                {/*Entrada 30% */}
                {parseFloat(
                  (
                    inmueble.preciototal * 30) / 100 + inmueble.abono
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="cuotas">
                {/*Cuotas Mensuales 36*/}
                {parseFloat(
                  inmueble.cuotas
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td>
                {/*Contra Entrega - Total 60% / 100*/}
                {parseFloat(
                  inmueble.contraentrega
                ).toLocaleString("en", { style: "currency", currency: "USD" })}
              </td>

              <td id="cuota_al_banco">
                {/*Cuota al Banco*/}
                {inmueble.cuota_al_banco}
              </td>

              <td id="dormitorios">
                {/*Dormitorios*/}
                {inmueble.dormitorios}
              </td>


              <td id="banos">
                {/*Banos*/}
                {inmueble.banos}
              </td>

                <td id="plano">
                {inmueble.plano ? (
                 <a href={inmueble.plano} target="_blank" > VER PLANO </a>
                ) : null}
                </td>

            </tr>
          );
        })
      ) : (
        <tr className="advertencia">
          <td>Sin datos para mostrar.</td>
        </tr>
      )}
    </>
  );
};

export default Filas;